html {
  @include initialQuery {
    font-size: 15px;
  }
  @include lg {
    font-size: 15px;
  }
  @include xl {
    font-size: 16px;
  }
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: $font-1;
  font-weight: 400;
  color: $primario-1;
  strong {
    font-weight: 700;
  }
  .condensed {
    font-family: $font-2;
    font-weight: 400;
    &.strong,
    strong {
      font-weight: 700;
    }
  }
  a,
  .btn,
  .enlace {
    @include transitionR;
  }
  a,
  .enlace {
    font-weight: 700;
    color: $secundario-1;
    &:hover {
      color: $secundario-2;
    }
  }
  .enlace {
    font-weight: 700;
    font-family: $font-h;
  }
  .btn {
    color: #fff;
    font-weight: 100;
    font-family: $font-h;
    font-size: 1.1rem;
    background-color: $secundario-1;
    border-radius: 0;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    line-height: 100%;
    @include sombrica1;
    &:hover,
    &:focus {
      color: #fff;
      background-color: $secundario-2;
      @include sombrica2;
    }
    &.btn-outline {
      border: 2px solid $secundario-1;
      background-color: #fff;
      color: $secundario-1;
      &:hover,
      &:focus {
        color: $secundario-1-80;
        border-color: $secundario-1-80;
        background-color: #fff;
      }
    }
  }
  .btn i {
    margin-left: 10px;
  }
  .btn-icono {
    text-align: center;
  }
  .btn-icono i {
    margin: 0 auto;
  }
  .rojo {
    color: #f56600;
  }
  .bk-rojo {
    color: #f56600;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: $primario-2;
    font-weight: 100;
    font-family: $font-h;
    &.strong,
    strong {
      font-weight: 700;
    }
  }
  h1 {
    font-size: 2.6rem;
    @include max-768 {
      font-size: 2.2rem;
    }
    @include sm {
      font-size: 2rem;
    }
  }
  h2 {
    font-size: 2.2rem;
    @include max-768 {
      font-size: 1.8rem;
    }
  }
  h3 {
    font-size: 2rem;
    @include max-768 {
      font-size: 1.6rem;
    }
  }
  h4 {
    font-size: 1.6rem;
    @include max-768 {
      font-size: 1.3rem;
    }
  }
  h5 {
    font-size: 1.4rem;
    @include max-768 {
      font-size: 1.2rem;
    }
  }
  h6 {
    font-size: 1.2rem;
    @include max-768 {
      font-size: 1.1rem;
    }
  }

  .italica{
    font-style: italic;
  }

  header#header-principal {
    position: sticky;
    top: 0;
    z-index: 1000;
    @include transitionR;
    &.fixed {
      top: -125px;
      @include sm {
        top: -105px;
      }
      @include xs {
        top: -95px;
      }
    }
    nav.nav-principal {
      position: relative;
      z-index: 20;
      height: 80px;
      background-color: #fff;
      border-bottom: 2px solid $secundario-1;
      @include sombrica1;
      @include sm {
        height: 60px;
      }
      @include xs {
        height: 50px;
      }
      .container {
        width: 100%;
        @include flex(nowrap, space-between, stretch, center);
        .brand-logo {
          position: relative;
          width: auto;
          height: 80px;
          @include flex(wrap, center, center, center);
          @include md-max {
            left: initial;
            transform: none;
          }
          @include sm {
            height: 60px;
          }
          @include xs {
            height: 50px;
          }
          svg {
            @include sm {
              max-width: 180px;
            }
            path {
              color: $secundario-1;
              @include md-max {
                fill: #fff;
              }
            }
          }
          img {
            width: 250px;
            @include sm {
              width: 200px;
            }
          }
          h1 {
          }
        }
        .menu-navegacion {
          font-family: $font-h;
          @include flex(nowrap, space-between, stretch, center);
          li.menu-item {
            background: none;
            height: 100%;
            position: relative;
            &:hover {
              background: none;
            }
            a.enlace-menu {
              font-weight: 100;
              text-transform: uppercase;
              background: none;
              height: 100%;
              color: $primario-2;
              font-size: 0.9rem;
              line-height: 100px;
              &:hover,
              &.active,
              &:focus {
                background: none;
                outline: none;
              }
              &.active,
              &:hover {
                color: $secundario-1;
              }
              &:focus {
                color: $primario-2;
              }
              i {
                font-size: 0.8rem;
                margin-left: 5px;
                color: $primario-2;
              }
            }
            &#menu-info {
              a {
                font-size: 1.2rem;
              }
            }
          }
        }
        .btn-menu-movil {
          display: none;
          float: none;
          margin: 0;
          padding: 0;
          width: 50px;
          height: 78px;
          color: $secundario-1;
          @include sm {
            height: 58px;
          }
          @include xs {
            height: 48px;
          }
          i {
            height: 78px;
            line-height: 78px;
            @include sm {
              height: 58px;
              line-height: 58px;
            }
            @include xs {
              height: 48px;
              line-height: 48px;
            }
          }
          @include md-max {
            @include flex(wrap, center, center, center);
            &.btn-menu {
              justify-content: flex-start;
              padding-left: 10px;
            }
            &.btn-info {
              justify-content: flex-end;
              padding-right: 10px;
            }
          }
        }
      }
    }
    nav.nav-busqueda {
      z-index: 10;
      height: 40px;
      line-height: 40px;
      background-color: $primario-1;
      box-shadow: none;
      border-bottom: 1px solid $gris-2;
      @include sombrica2;
      .container {
        margin: 0 auto;
        @include sm {
          padding: 0;
        }
        i {
          height: 38px;
          line-height: 38px;
          font-size: 1.4rem;
          color: $gris-4;
        }
        form {
          /* max-width: 800px; */
          margin: 0 auto;
          .input-field {
            margin: 0 auto;
            input {
              padding-left: 50px;
              padding-right: 50px;
              width: calc(100% - 100px);
              box-shadow: none;
              background: none;
              margin: 0;
              border: none;
              font-family: $font-2;
              font-size: 0.9rem;
              color: #fff;
              &:focus {
                color: $primario-2;
                background-color: #fff;
              }
              @include sm {
                /* padding: 0 20px 0 60px; */
              }
            }
          }
          ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $secundario-1;
            opacity: 1; /* Firefox */
            font-family: $font-2;
            font-size: 0.8rem;
          }

          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $secundario-1;
            opacity: 1; /* Firefox */
            font-family: $font-2;
            font-size: 0.8rem;
          }

          ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $secundario-1;
            opacity: 1; /* Firefox */
            font-family: $font-2;
            font-size: 0.8rem;
          }
        }
      }
      .input-field input[type="search"] + .label-icon {
        left: 10px;
        @include sm {
          left: 22px;
          font-size: 1.2rem;
        }
      }
      .input-field input[type="search"] ~ .mdi-navigation-close,
      .input-field input[type="search"] ~ .material-icons {
        position: absolute;
        top: 0;
        right: 10px;
        color: transparent;
        cursor: pointer;
        transition: 0.3s color;
        @include sm {
          right: 25px;
        }
      }
      .input-field input[type="search"]:focus:not(.browser-default) + label i,
      .input-field
        input[type="search"]:focus:not(.browser-default)
        ~ .mdi-navigation-close,
      .input-field
        input[type="search"]:focus:not(.browser-default)
        ~ .material-icons {
        color: $secundario-1;
      }
    }
  }
  .sub-menu-escritorio {
    top: 78px !important;
    width: max-content !important;
    height: max-content !important;
    background-color: $secundario-1;
    left: 50% !important;
    transform: translateX(-50%) !important;
    li {
      min-height: 40px;
      background: none;
      &.active,
      &:hover,
      &:focus {
        background: none;
      }
      a {
        font-weight: 100;
        color: #fff;
        font-size: 0.8rem;
        min-height: 40px;
        min-height: 40px;
        padding: 10px;
        display: inline-block;
        width: 100%;
      }
    }
  }

  .sidenav {
    z-index: 1020;
  }
  .sidenav-overlay {
    background-color: $primario-1-98;
    z-index: 1010;
  }
  #menu-movil {
    font-family: $font-h;
    box-shadow: none;
    width: 100vw;
    background: none;
    height: 100vh;
    padding-bottom: 0;
    .cerrar-sidenav {
      text-align: right;
      position: sticky;
      top: 0;
      z-index: 100;
      a {
        color: #fff;
        font-size: 2rem;
        margin: 0;
        padding: 0;
        width: 80px;
        height: 80px;
        line-height: 80px;
        margin-left: auto;
        @include flex(wrap, center, center, center);
        &:hover {
          background: none;
        }
        i {
          color: #fff;
          margin: 0 auto;
        }
      }
    }
    .menu-item {
      width: 100%;
      border-bottom: 1px solid $blanco50;
      @include flex(wrap, flex-start, center, center);
      .enlace-movil {
        width: 100%;
      }
      & > .enlace-movil {
        font-size: 1.6rem;
        height: auto;
      }
      .enlace-movil {
        color: #fff;
        display: block;
        font-weight: 500;
        line-height: 48px;
        padding: 0 32px;
      }
      .sub-menu-movil {
        width: 100%;
        display: none;
        padding: 15px 0;
        background-color: $primario-2-98;
        li {
          a {
            color: #fff;
            font-size: 1.2rem;
            height: auto;
            line-height: 130%;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
        &.submenu-badges {
          li {
            position: relative;
            a {
              padding-right: 60px;
              .badge {
                @include flex(wrap, center, center, center);
                width: 30px;
                height: 30px;
                border-radius: 100%;
                background-color: transparent;
                color: $gris-3;
                border: 1px solid $gris-3b;
                font-size: 0.9rem;
                margin: 0;
                float: none;
                position: absolute;
                right: 25px;
                @include centrado-vertical;
                min-width: auto;
                min-height: auto;
              }
            }
          }
        }
      }
    }
    .menu-item:last-child {
      border: none;
    }
    .menu-item-desplegable {
      justify-content: space-between;
      .enlace-movil {
        width: calc(100% - 80px);
      }
      .btn-desplegable {
        width: 80px;
        height: 48px;
        text-align: center;
        margin: 0;
        padding: 0;
        @include flex(wrap, center, center, center);
        &:focus,
        &:hover {
          background: none;
        }
        &.open {
          transform: rotate(180deg);
        }
        i {
          margin: 0 auto;
          padding: 0;
          font-size: 1.6rem;
          color: $blanco65;
        }
      }
    }
    .menu-item-archivo {
      background-color: $blanco5;
      bottom: 80px;
      padding: 10px 32px;
      .form-archivo {
        margin: 0 auto;
        width: 100%;
        select {
          width: 100%;
          font-size: 1.2rem;
          color: $secundario-1;
        }
      }
    }
    .menu-item-redes {
      bottom: 0;
      left: 0;
      width: 100vw;
      padding: 20px 0;
      @include flex(wrap, center, center, center);
      a {
        @include flex(wrap, center, center, center);
        width: 33.33%;
        line-height: 110%;
        height: 80px;
        &.facebook {
          /* background-color: $facebook-1; */
        }
        &.twitter {
          /* background-color: $twitter-1; */
        }
        &.youtube {
          /* background-color: $youtube-1; */
        }
        span {
          color: #fff;
          line-height: 110%;
          text-align: center;
          font-size: 0.9rem;
        }
        i {
          color: #fff;
          text-align: center;
          width: 100%;
          margin: 0 auto;
          float: none;
          line-height: 120%;
          height: auto;
          font-size: 1.6rem;
          margin-bottom: 5px;
        }
        i.fa-twitter-x {
          height:26px;
          background-image: url('../../../images/twitter-x.svg');
          background-repeat: no-repeat;
          background-position: bottom;
        }
      }
    }
    .menu-item-fijo {
      position: sticky;
      z-index: 100;
    }
  }

  nav.miguitas {
    height: 25px;
    line-height: 25px;
    .container {
      .ul-miguitas {
        font-size: 0.8rem;
        font-weight: 300;
        font-family: $font-2;
        li {
          a {
            max-width: 250px;
            @include truncated;
            color: $primario-2;
            font-family: $font-2;
            font-size: 0.7rem;
            font-weight: 300;
            opacity: 0.7;
            border-right: 1px solid $negro10;
            &:hover {
              opacity: 1;
            }
          }
          &.active {
            background-color: $negro5;
            a {
              opacity: 1;
              border: none;
              &:hover{
                background: none;
              }
            }
          }
        }
      }
    }
    &.escritorio{
      background-color: $gris-3;
      @include sombrica0;
      @include md-max{
        display: none;
      }
    }
    &.movil{
      margin-top: 20px;
      min-height: 25px;
      height: auto;
      background-color: $gris-3;
      @include flex(wrap, space-between, center, center);
      @include lg{
        display: none;
      }
      .container{
        .ul-miguitas{
          li{
            margin: 5px 0;
            a{
              font-weight: 400;
              opacity: 0.9;
              font-size: 0.8rem;
              color: $primario-2;
            }
          }
        }
      }
    }
  }

  .banner {
    z-index: 10;
    position: relative;
    padding: 40px 0;
    border-bottom: 3px solid $gris-4;
    @include sombrica2;
    position: relative;
    min-height: 200px;
    @include flex(wrap, center, center, center);
    @include background-img(
      #fff,
      url(../images/fondo-banner.jpg),
      center,
      center,
      cover
    );
    @include max-768 {
      padding: 30px 0;
      min-height: auto;
    }
    .overlay-banner {
      opacity: 0.85;
      @include degradado-claro;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .container {
      text-align: center;
      position: relative;
      z-index: 20;
      h1 {
      }
    }
    &.banner-claro {
      @include degradado-claro;
      @include sm {
        background: none;
        background-color: #fff;
      }
    }
  }

  .container {
    width: 100%;
    max-width: $max-width;
    padding: 0 10px;
    margin: 0 auto;
  }

  main {
    &.main-comun {
      @include lg {
        min-height: calc(100vh - 465px);
      }
    }
  }

  .section-normal {
    padding: 40px 0;
    &.section-articulo{
      padding-top: 10px;
      @include lg{
        padding-top: 20px;
      }
    }
  }

  #footer {
    position: relative;
    @include sombricaTop;
    border-top: 2px solid $secundario-2;
    font-family: $font-h;
    text-align: center;
    z-index: 10;
    color: $primario-2;
    @include sombrica1;
    @include lg {
      height: 350px;
      @include flex(nowrap, center, center, center);
    }
    .container {
      @include md-max {
        padding: 40px 10px 20px 10px;
      }
      a {
        color: $primario-2;
      }
      p {
        line-height: 130%;
        margin: 0;
        font-weight: 700;
      }
      .logo {
        margin-bottom: 10px;
      }
      .txt {
        font-size: 1.1rem;
        margin-bottom: 15px;
      }
      .tel {
        font-size: 1.6rem;
        font-weight: 100;
        margin-bottom: 5px;
        i {
          opacity: 0.5;
          font-size: 1.2rem;
          margin-right: 10px;
          position: relative;
          vertical-align: middle;
        }
      }
      .fax {
        margin-bottom: 15px;
      }
      .email {
        margin-bottom: 25px;
        font-size: 0.9rem;
      }
      .tel,
      .email {
        @include min-769 {
          a:hover {
            color: $secundario-1;
          }
        }
      }
      .redes-sociales {
        margin: 0 auto;
        @include flex(nowrap, center, center, center);
        li {
          margin: 0 10px;
          a {
            width: 30px;
            display: inline-block;
            text-align: center;
            font-size: 1.4rem;
            opacity: 0.7;
            &:hover {
              @include min-769 {
                opacity: 1;
                color: $secundario-1;
              }
            }
            i.fa-twitter-x {
              height:18px;
              width: 18px;
              background-image: url('../../../images/twitter-x.svg');
              background-repeat: no-repeat;
              background-position: bottom;
              filter: invert(65%);
            }
            i.fa-twitter-x:hover {
              background-image: url('../../../images/twitter-x-naranja.svg');
              filter: unset;
          }
          }
        }
      }
    }
  }
  .info-footer {
    min-height: 35px;
    text-align: center;
    background-color: $gris-1;
    color: $negro65;
    font-size: 0.7rem;
    padding: 8px 0;
    a {
      color: $primario-2;
      @include sm {
        margin: 5px;
        display: inline-block;
      }
    }
  }
}
