body{
  .centro-centro{
    max-width: 800px;
    @include sm{
      margin-top: 0;
    }
    .img-centro{
      img{
        min-height: 120px;
      }
    }
  }
  .mapa-centro{
    margin: 15px auto 30px auto;
    width: 100%;
    max-width: 800px;
    @include sombrica1;
  }
  .container-imagenes-centro{
    max-width: 95%;
    margin: 15px auto 30px auto;
    @include max-768{
      max-width: 88%;
    }
    @include sm{
      max-width: 85%;
      margin-bottom: 15px;
    }
    .container-img{
      img{
        float: none;
        margin: 0 auto;
        @include sombrica1;
      }
    }
  }
  .como-llegar{
    background-color: $maps;
    padding: 15px 10px;
    @include sm{
      padding: 10px;
    }
    .transportes{
      padding-left: 30px;
      margin-bottom: 10px;
      .transporte{
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        color: $blanco80;
        @include flex(wrap, center, center, center);
        display: inline-flex;
        margin-right: 10px;
        &:hover{
          background-color: $blanco15;
        }
        &.active{
          background-color: $negro15;
          color: #fff;
        }
        i{
          font-size: 1.4rem;
        }
      }
      .transporte:last-child{
        margin: 0;
      }
    }
    #form-transporte{
      margin: 0;
      @include flex(wrap, space-between, center, center);
      .inputs{
        width: calc(100% - 40px);
        padding-right: 10px;
        .input-field{
          &.input-transporte{
            margin-top: 0;
            margin-bottom: 10px;
            .prefix{
              width: 30px;
              color: $blanco80;
              font-size: 1rem;
              height: 35px;
              text-align: center;
              padding-right: 10px;
              padding-top: 2px;
            }
            input{
              height: 35px;
              color: $blanco90;
              background: none;
              box-shadow: none;
              margin: 0;
              margin-left: 30px;
              width: calc(100% - 45px);
              border: none;
              border-bottom: 1px solid $blanco80;
            }
          }
        }
      }
      #btn-transporte{
        width: 40px;
        @include flex(wrap, center, center, center);
        color: $blanco90;
        padding-right: 10px;
        padding-top: 20px;
        &:hover{
          color: #fff;
        }
        i{
          font-size: 1.6rem;
        }
      }
      ::placeholder { 
        color: $blanco80;
        opacity: 1; 
        font-family: $font-2;
      }
      
      :-ms-input-placeholder {
        color: $blanco80;
        font-family: $font-2;
      }
      
      ::-ms-input-placeholder { 
        color: $blanco80;
      }
    }
  }
}