body {
  .container-contacto-centros {
    @include min-769 {
      @include flex(wrap, space-between, flex-start, center);
    }
    .container-contacto {
      @include min-769 {
        width: 40%;
        padding-right: 15px;
      }
      h3 {
        font-size: 1.4rem;
      }
      #form-contacto {
        margin: 15px 0 30px 0;
        @include max-768 {
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;
        }
        .input-field {
          margin-top: 0;
          margin-bottom: 15px;
          input,
          select,
          textarea {
            margin: 0;
          }
        }
        .input-boton {
          .btn {
            @include xs {
              width: 100%;
            }
          }
        }
      }
    }
    .container-centros {
      @include min-769 {
        padding-left: 15px;
        width: 60%;
      }
      @include xl {
        padding-left: 100px;
      }
      h2 {
        font-size: 1.2rem;
        @include min-769 {
          padding-top: 4px;
        }
      }
    }
  }
  .centro {
    margin: 15px auto;
    width: 100%;
    @include flex(wrap, flex-start, center, center);
    background-color: $gris-3;
    @include sombrica0;
    @include max-768 {
      margin: 20px auto;
      max-width: 400px;
    }
    .img-centro {
      width: 180px;
      align-self: stretch;
      display: flex;
      @include max-768 {
        width: 100px;
      }
      img {
        object-fit: cover;
        object-position: 50% 50%;
        @include transitionR;
        min-height: 140px;
      }
    }
    .txt-centro {
      font-weight: 300;
      font-family: $font-2;
      line-height: 100%;
      padding: 15px;
      width: calc(100% - 180px);
      @include max-768 {
        width: calc(100% - 100px);
      }
      .titulo-centro {
        color: $primario-2;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 1.2rem;
      }
      .datos-centro {
        color: $primario-2;
        .telefono {
          margin: 0;
        }
        .calle-cp {
          margin: 5px 0 10px 0;
          width: 100%;
          @include flex(wrap, flex-start, center, center);
          .calle {
            width: 100%;
            margin-top: 0;
            margin-bottom: 5px;
          }
          .cp {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
      .mapa {
        @include flex(wrap, flex-start, center, center);
        font-size: 0.8rem;
        margin: 0;
        @include xs {
          font-size: 0.9rem;
        }
        img {
          margin-left: 5px;
          transform: translateY(-2px);
          width: 20px;
        }
      }
    }
  }
  a.centro:hover {
    @include sombrica1;
    background-color: #fff;
    .img-centro {
      img {
        @include brillico;
      }
    }
  }
}
