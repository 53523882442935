body{
  .resaltado{
    background-color: $gris-3;
    margin-bottom: 20px;
    padding: 20px 15px;
    @include md-max{
      padding: 15px 10px;
    }
    p:first-child{
      margin-top: 0;
    }
    p:last-child{
      margin-bottom: 0;
    }
    &.resaltado-privacidad{
      p:first-child{
        font-size: 1.2rem;
      }
    }
  }
  .alert{
    padding: 20px 15px;
    font-size: 1.2rem;
    font-family: $font-2;
    text-align: center;
    @include sombrica1;
    &.alert-light{
      color: $primario-2;
      background-color: $blanco90;
    }
    p:first-child{
      margin-top: 0;
    }
    p:last-child{
      margin-bottom: 0;
    }
    &.alert-success{
      color: $success-1;
      background-color: $success-2;
    }
    &.alert-warning{
      color: $warning-1;
      background-color: $warning-2;
    }
    &.alert-danger{
      color: $danger-1;
      background-color: $danger-2;
    }
  }
  .txt-izq{
    @include lg{
      padding-right: 15px;
    }
  }
  .txt-der{
    @include lg{
      padding-left: 15px;
    }
  }
  blockquote{
    border: none;
    font-style: italic;
    font-weight: 500;
    padding: 15px;
    background-color: $gris-3b;
  }
  .title-section{
    margin-top: 20px;
    margin-bottom: 15px;
    padding-bottom: 5px;
    display: inline-block;
    font-family: $font-h;
    font-size: 1.4rem;
    color: $primario-2;
    border-bottom: 2px solid $gris-2;
    box-shadow: 0 2px $gris-3;
    width: 100%;
    @include sm{
      text-align: center;
    }
    i{
      position: relative;
      vertical-align: middle;
      transform: translateY(-3px);
      font-size: 1.6rem;
      color: $secundario-2;
      margin-right: 5px;
    }
    img{
      position: relative;
      vertical-align: middle;
      transform: translateY(-3px);
      width: 22px;
      margin-right: 5px;
    }
    &.title-sidebar{
      font-size: 1.2rem;
      padding-top: 6px;
      i{
        font-size: 1.4rem;
        transform: translateY(-2px);
      }
    }
  }
  .container-articulos{
    
  }
  .articulo{
    pointer-events: none;
    display: inline-block;
    width: 100%;
    margin: 20px 0;
    padding-bottom: 20px;
    border-bottom: 2px solid $gris-2;
    box-shadow: 0 2px $gris-3;
    .articulo-enlace{
      font-weight: 400;
      color: $primario-2;
      @include flex(wrap, space-between, center, center);
      @include md-max{
        justify-content: center;
      }
      .articulo-img{
        overflow: hidden;
        @include sombrica1;
        @include lg{
          width: 200px;
          height: 128px;
        }
        @include xl{
          width: 250px;
          height: 160px;
        }
        @include md-max{
          width: 100%;
          height: 250px;
          overflow: visible;
          text-align: center;
          box-shadow: none;
          margin-bottom: 15px;
        }
        @include sm{
          height: auto;
        }
        img{
          position: relative;
          @include transitionR;
          @include lg{
            @include centrado-total;
          }
          @include md-max{
            margin: 0 auto;
            @include sombrica1;
            max-height: 250px;
          }
          @include sm{
            max-height: 260px;
          }
        }
      }
      .articulo-txt{
        padding-left: 20px;
        @include lg{
          width: calc(100% - 200px);
        }
        @include xl{
          width: calc(100% - 250px);
        }
        @include md-max{
          width: 100%;
          padding-left: 0;
        }
        @include sm{
          /* text-align: center; */
        }
        .articulo-titulo{
          pointer-events: all;
          line-height: 120%;
          font-size: 1.6rem;
          font-weight: 700;
          @include transitionR;
          @include sm{
            /* text-align: center; */
          }
        }
        .articulo-parrafo{
          margin-top: 10px;
          font-size: 0.9rem;
          @include transitionR;
          @include sm{
            font-size: 1rem;
          }
          p:first-child{
            margin-top: 0;
          }
          p:last-child{
            margin-top: 0;
          }
        }
      }
      &:hover{
        .articulo-img{
          img{
            @include brillico;
          }
        }
        .articulo-txt{
          .articulo-titulo,
          .articulo-parrafo{
            color: $secundario-1;
          }
        }
      }
    }
  }

  .articulo-tags,
  .articulo-compartir{
    @include lg{
      padding-left: 220px;
    }
    @include xl{
      padding-left: 270px;
    }
    @include md-max{
      padding: 0;
      text-align: center;
    }
  }
  .articulo-tags{
    .tags{
      padding: 2px;
      text-align: right;
      color: $secundario-1-50;
      margin: 0;
      @include md-max{
        text-align: center;
      }
      @include max-768{
        @include flex(wrap, center, center, space-between);
      }
      @include sm{
        justify-content: flex-start;
      }
      li{
        display: inline;
        margin: 0 2px;
        @include sm{
          padding: 5px;
          display: inline-block;
        }
        a{
          font-family: $font-2;
          background-color: $gris-2;
          padding: 2px 4px;
          font-size: 0.7rem;
          font-weight: 500;
          color: $primario-2;
          &:hover{
            color: $primario-1;
            background-color: $gris-1;
          }
        }
      }
      .fecha{
        a{
          font-size: 0.8rem;
          font-weight: 700;
        }
      }
    }
  }
  .articulo-compartir{
    margin-top: 10px;
    @include transitionR;
    @include max-768{
      margin-top: 15px;
    }
    .compartir{
      text-align: right;
      margin: 0;
      @include flex(wrap, flex-end, center, center);
      @include md-max{
        text-align: center;
      }
      li{
        display: inline;
        a{
          width: 30px;
          text-align: center;
          display: inline-block;
          @include max-768{
            width: 40px;
            margin: 0 5px;
            font-size: 1.8rem;
          }
          @include sm{
            width: 35px;
            font-size: 1.8rem;
          }
        }
        span{
          color: $primario-2;
          font-size: 0.8rem;
          font-family: $font-2;
          font-weight: 700;
          @include md-max{
            font-weight: 700;
            font-size: 0.9rem;
          }
        }
      }
      .sharon{
        cursor: pointer;
        background-color: $gris-5;
        border: none;
        @include flex(wrap, center, center, center);
        display: inline-flex;
        width: 40px;
        margin: 0 5px;
        height: 40px;
        i{
          font-size: 1.2rem;
        }
        &:hover{
          background-color: #fff;
        }
        &.sharon_facebook{
          i{
            color: $facebook;
          }
        }
        &.sharon_twitter{
          i{
            color: $twitter;
          }
        }
        &.sharon_linkedin{
          i{
            color: $linkedin;
          }
        }
        &.sharon_whatsapp{
          i{
            color: $wathsapp;
          }
        }
      }
    }
  }

  .span-tags {
    color: $primario-2;
    font-size: 0.8rem;
    font-family: $font-2;
    font-weight: 700;
    @include md-max {
      font-weight: 700;
      font-size: 0.9rem;
    }
  }

  .paginacion-articulos{
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    .pagination{
      li{
        margin: 0 4px;
        width: 30px;
        height: 30px;
        border-radius: 0;
        &.active{
          background-color: $gris-1;
          a{
            color: $primario-1;
          }
          &:hover{
            background-color: $gris-1;
            a{
              color: $primario-1;
            }
          }
        }
        a{
          color: $secundario-1;
          font-size: 1rem;
          font-family: $font-h;
          font-weight: 700;
          i{
            color: $secundario-1;
          }
        }
        &:hover{
          background-color: $secundario-1-15;
          a{
            /* color: $primario-1; */
          }
        }
        &.pagination-flecha{
          background: none;
          &:hover{
            background: none;
          }
          &.disabled{
            opacity: 0.7;
            a{
              color: $gris-1;
              i{
                color: $primario-2;
              }
            }
          }
        }
      }
    }
  }
  .collection{
    border: 2px solid $gris-2;
    .collection-item{
      padding: 12px 20px;
      border-radius: 0;
      font-family: $font-h;
      font-weight: 100;
      color: $secundario-1;
      background-color: #fff;
      line-height: 110%;
      &:not(.active):hover,
      &.active{
        background-color: $gris-3b;
        color: $primario-2;
      }
    }
    &.collection-imgs{
      .collection-item{
        padding: 0 15px 0 0;
        @include flex(wrap, space-between, center, center);
        .collection-img{
          @include transitionR;
          filter: grayscale(100%);
          position: relative;
          width: 80px;
          height: 70px;
          overflow: hidden;
          background-color: $negro90;
          border-right: 1px solid $gris-2;
          img{
            position: relative;
            top:50%;
            left:50%;
            height: 90%;
            transform-origin: center center;
            transform: translate(-50%, -50%) scale(1.2);
          }
        }
        span{
          width: calc(100% - 80px);
          padding-left: 10px;
        }
        &:hover{
          .collection-img{
            filter: grayscale(0);
          }
        }
      }
    }
    &.collection-badges{
      .collection-item{
        position: relative;
        padding-right: 40px;
        .badge{
          @include flex(wrap, center, center, center);
          width: 30px;
          height: 30px;
          border-radius: 100%;
          background-color: $gris-3b;
          font-size: 0.9rem;
          margin: 0;
          float: none;
          position: absolute;
          right: 10px;
          @include centrado-vertical;
          min-width: auto;
          min-height: auto;
        }
        &:hover{
          .badge{
            background-color: #fff;
          }
        }
      }
    }
    &.collection-redes{
      border: none;
      @include xl{
        @include flex(nowrap, space-between, stretch, center);
      }
      i{
        margin-right: 10px;
        font-size: 1.4rem;
        width: 30px;
        text-align: center;
        @include xl{
          width: 100%;
          margin: 0 auto;
          margin-bottom: 5px;
          height: 22px;
        }
      }
      .fa-twitter-x {
        height:18px;
        // width: 18px;
        background-image: url('../../../images/twitter-x.svg');
        background-repeat: no-repeat;
        background-position: bottom;
      }
      .collection-item{
        margin: 2px;
        color: #fff;
        @include xl{
          height: 100%;
          text-align: center;
        }
        &:hover{
          color: #fff;
          @include sombrica1;
        }
        &.facebook{
          background-color: $facebook-1;
          &:hover{
            background-color: $facebook-2;
          }
        }
        &.twitter{
          background-color: $twitter-1;
          &:hover{
            background-color: $twitter-2;
          }
        }
        &.youtube{
          background-color: $youtube-1;
          &:hover{
            background-color: $youtube-2;
          }
        }
      }
    }
  }
  .form-archivo{
    margin: .5rem 0 1rem 0;
  }
}