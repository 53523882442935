body {
  .container-opiniones-casos {
    @include xl {
      @include flex(wrap, space-between, flex-start, center);
    }
    .container-opiniones {
      @include xl {
        width: 55%;
        padding-right: 15px;
      }
      .opinion{
        padding: 25px 20px;
        background-color: $gris-3b;
        margin: 15px 0;
        @include sombrica0;
        @include max-768{
          margin: 20px 0;
        }
        @include sm{
          padding: 15px 10px;
        }
        .txt-fecha{
          line-height: 120%;
          font-size: 0.8rem;
          margin-top: 0;
          font-family: $font-2;
          color: $primario-2;
          opacity: 0.7;
          @include lg-max{
            font-size: 0.9rem;
            margin-bottom: 15px;
          }
          .txt{
            
          }
          .fecha{
            font-weight: 700;
          }
        }
        .txt-opinion{
          font-family: $font-h;
          font-style: italic;
          position: relative;
          line-height: 120%;
          .comilla{
            font-style: normal;
            font-size: 2rem;
            line-height: normal;
            font-weight: 700;
            color: $secundario-1;
            position: absolute;
            &.comilla-1{
              transform: translate(-10px, -10px);
            }
            &.comilla-2{
              transform: translate(0, 0);
            }
          }
        }
        .cita-opinion{
          margin-bottom: 0;
          padding-top: 5px;
          border-top: 1px solid $gris-2;
          line-height: 120%;
          color: $primario-2;
          font-family: $font-2;
          font-size: 0.9rem;
          @include lg-max{
            padding-top: 10px;
          }
          .cita-1{
            font-weight: 700;
          }
          .cita-2{
            font-size: 0.8rem;
            font-style: italic;
            @include lg-max{
              font-size: 0.9rem;
            }
          }
        }
      }
    }
    .container-casos {
      @include xl {
        width: 45%;
        padding-left: 15px;
        padding-top: 3px;
      }
      p{
        font-size: 0.9rem;
        @include lg-max{
          font-size: 1rem;
        }
      }
      .caso{
        margin: 15px 0;
        background-color: $gris-3;
        @include flex(wrap, flex-start, flex-start, center);
        @include sombrica0;
        @include max-768{
          margin: 20px 0;
        }
        .img-caso{
          max-height: 140px;
          width: 140px;
          @include xl{
            width: 100px;
          }
          @include max-768{
            width: 100px;
            min-height: 120px;
          }
          @include sm{
            min-height: 140px;
          }
          @include xs{
            min-height: 160px;
          }
          img{
            object-fit: cover;
            object-position: 50% 50%;
            width: 140px;
            min-height: 140px;
            @include max-768{
              width: 100px;
              min-height: 140px;
            }
            @include sm{
              min-height: 160px;
            }
            @include xs{
              min-height: 180px;
            }
          }
        }
        .txt-caso{
          color: $primario-2;
          padding: 15px 10px 10px 10px;
          line-height: 120%;
          width: calc(100% - 140px);
          @include xl{
            width: calc(100% - 100px);
          }
          @include max-768{
            width: calc(100% - 100px);
          }
          font-family: $font-2;
          .title-caso{
            font-weight: 700;
            font-size: 1.1rem;
            margin-top: 0;
            margin-bottom: 5px;
            @include lg-max{
              margin-bottom: 10px;
            }
          }
          .opinion-caso{
            margin-top: 0;
            margin-bottom: 0;
          }
        }
        .btn-opinion{
          border-top: 1px solid $gris-2;
          background-color: $gris-3b;
          padding: 10px 0 15px 0;
          margin-top: 0;
          width: 100%;
        }
      }
    }
  }
  .btn-opinion{
    margin-top: 15px;
    text-align: center;
    .btn{
      font-size: 0.8rem;
      i{
        font-size: 0.8rem;
      }
    }
  }
  .btn-ver-mas{
    border-top: 1px solid $gris-2;
    padding-top: 10px;
    margin-top: 20px;
    text-align: center;
    @include lg-max{
      margin-bottom: 20px;
    }
    a{
      i{
        font-size: 1.6rem;
        margin-left: 10px;
        position: relative;
        vertical-align: middle;
      }
    }
  }
}
