body{
  #main-privacidad{
    .col-articulos{
      .titulo-privacidad{
        margin: 0;
        padding: 0;
        border: none;
        box-shadow: none;
        font-size: 1.2rem;
        width: auto;
        font-weight: 700;
      }
    }
  }
  .ul-ventajas{
    padding-left: 30px;
    list-style-type: circle;
    li{
      list-style-type: circle;
      margin-bottom: 15px;
    }
    li:last-child{
      margin-bottom: 0;
    }
  }
  .col-menu-privacidad{
    position: sticky;
    top: 140px;
  }
  .ul-menu-indice{
    li{
      padding-bottom: 10px;
      a{
        font-weight: 400;
        font-size: 0.8rem;
        background-color: $gris-3b;
        padding: 5px 10px;
        &:hover,
        &.active{
          background-color: $secundario-1;
          color: #fff;
        }
      }
    }
  }
}