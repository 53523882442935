body {
  .container-tags-articulo {
    position: sticky;
    z-index: 900;
    background-color: #fff;
    padding: 5px 0;
    border-bottom: 1px solid $gris-3;
    @include transitionR;
    &.fixed {
      @include sombrica0;
    }
    .container {
      @include md-max {
        @include sm {
          padding: 0;
        }
      }
      .articulo-tags,
      .articulo-compartir {
        padding: 0;
      }
      .articulo-tags {
        .tags {
          text-align: center;
          li {
            a {
            }
          }
          .fecha {
            a {
            }
          }
        }
      }
      .articulo-compartir {
        @include max-768 {
          margin-top: 10px;
        }
        .container-compartir{
          text-align: center;
          justify-content: center;
          .fa-twitter-x {
            height:18px;
            width: 18px;
            background-image: url('../../../images/twitter-x.svg');
            background-repeat: no-repeat;
            background-position: bottom;
            filter: invert(100%);
          }
        }
        .compartir {
          li {
            a {
              @include md-max {
                font-size: 1rem;
              }
            }
            span {
              @include sm {
              }
            }
          }
        }
      }
    }
  }
  .articulo-img-section {
    background-color: $gris-3;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    @include flex(wrap, center, center, center);
    img {
      max-height: 350px !important;
      margin: 0 auto !important;
      float: none !important;
    }
  }
  .section-interior {
    @include sm{
      padding: 20px 0;
    }
    .col-articulos {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @extend .title-section;
      }
      h1 {
        font-size: 1.8rem;
      }
      h2 {
        font-size: 1.7rem;
      }
      h3 {
        font-size: 1.6rem;
      }
      h3 {
        font-size: 1.5rem;
      }
      h4 {
        font-size: 1.4rem;
      }
      h5 {
        font-size: 1.2rem;
      }
      img{
        float: left;
        margin: 15px 15px 15px 0;
        @include flex(wrap, center, center, center);
      }
    }
  }
  .txt-img {
    @include lg {
      @include flex(wrap, space-between, flex-start, center);
    }
    .img {
      margin: 15px 0;
      text-align: center;
      @include lg {
        width: calc(40% - 30px);
        margin: 15px;
        background-color: $gris-3b;
        padding: 15px;
        img {
          margin: 0 auto;
        }
      }
      img {
        max-height: 300px;
      }
      cite {
        font-size: 0.8rem;
        display: block;
        color: $primario-2;
        @include lg {
          text-align: left;
        }
      }
    }
    .txt {
      @include lg {
        width: 60%;
      }
    }
  }
  .video-articulo {
    margin: 15px auto 30px auto;
    @include sm{
      margin-bottom: 15px;
    }
    .video-container {
      @include sombrica2;
    }
    @include md {
      max-width: 800px;
      .video-container{
        margin-bottom: 30px;
      }
    }
  }
  .container-tags {
    margin: 30px 0;
    padding: 10px 0;
    border-top: 1px solid $gris-2;
    border-bottom: 1px solid $gris-2;
    .tags {
      margin: 5px 0;
      font-family: $font-2;
      @include flex(wrap, flex-start, center, center);
      li {
        margin: 0 5px;
        a {
          padding: 2px 4px;
          background-color: $gris-2;
          font-size: 0.8rem;
          font-weight: 300;
          color: $primario-2;
          @include sm {
            font-size: 0.7rem;
          }
          &:hover {
            color: $primario-1;
            background-color: $gris-1;
          }
        }
        span {
          font-weight: 700;
          font-size: 0.9rem;
          color: $primario-2;
        }
      }
    }
  }
  .container-articulo-anterior-siguiente {
    margin: 15px 0;
    @include flex(wrap, space-between, center, center);
    .container-articulo-anterior {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-align: left;
      }
    }
    .container-articulo-siguiente {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-align: right;
      }
    }
    .container-articulo-anterior,
    .container-articulo-siguiente {
      width: 300px;
      @include max-768 {
        width: 100%;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          text-align: center;
        }
        .articulo-anterior{
          margin: 0 auto;
        }
      }
    }
  }
  .container-articulos-relacionados {
    max-width: 95%;
    margin: 15px auto;
    @include max-768{
      max-width: 88%;
    }
    @include sm{
      max-width: 85%;
    }
    .container-articulo {
      padding: 0 15px;
      .articulo-anterior{
        margin: 0 auto;
      }
    }
  }
  .col-articulos {
    .articulo-anterior {
      @include flex(wrap, center, center, center);
      background-color: $gris-3b;
      max-width: 300px;
      min-height: 210px;
      @include sombrica0;
      .img-articulo {
        @include flex(wrap, center, center, center);
        width: 100%;
        background-color: $gris-2;
        img {
          min-height: 150px;
          max-height: 150px;
          object-fit: cover;
          object-position: 50% 50%;
          margin: 0 auto;
          float: none;
        }
      }
      span {
        width: 100%;
        font-family: $font-2;
        font-weight: 300;
        font-size: 0.8rem;
        line-height: 100%;
        &.categoria {
          padding: 0 10px;
          color: $primario-2;
        }
        &.fecha {
          padding: 10px 10px 5px 10px;
          color: $primario-2;
        }
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        width: 100%;
        padding: 10px;
        margin: 0;
        border: none;
        box-shadow: none;
        font-size: 1rem;
        color: $secundario-1;
        text-align: left;
        @include transitionR;
      }
      img {
        @include transitionR;
      }
      &:hover {
        @include sombrica1;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $secundario-2;
        }
        img {
          @include brillico;
        }
      }
    }
  }
}
